<template>
  <div>
    <div class="field">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field-body">
            <div class="field has-addons">
              <div class="control has-icons-left has-icons-right">
                <input class="input"
                  v-model="filter.query"
                  type="text"
                  placeholder="Start typing to filter"
                  @input="search">
                <span class="icon is-left">
                  <i class="mdi mdi-magnify mdi-18px" />
                </span>
              </div>
              <div class="control">
                <a class="button is-primary is-outlined tooltip"
                  data-tooltip="Reset search"
                  @click="reset()">
                  <span class="icon">
                    <i class="mdi mdi-close mdi-24px" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="filter.pageIndex"
      :page-size="filter.pageSize"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      :sort-column="filter.sortColumn"
      :sort-order="filter.sortOrder"
      :draggable="false"
      @pageChanged="onPageChange"
      @sort="sort">
      <tr v-for="(item, index) in pagedActiveItems"
        :key="item.quoteItemId"
        @dblclick="addNewItem(item, index)"
        :class="{ 'is-selected' : selectedRow === index }">
        <td class="itemno-input">{{ item.itemNo }}</td>
        <td>{{ item.itemDesc }}</td>
        <td class="has-vertical-middle has-text-centered">
          <span class="icon has-text-info">
            <i class="mdi mdi-24px"
              :class="[ item.reportOnly ? 'mdi-check' : '' ]" />
          </span>
        </td>
        <td class="has-vertical-middle has-text-right">{{ item.hourValue | formatNumber($userInfo.locale) }}</td>
        <td class="has-vertical-middle">{{ item.itemType }}</td>
        <td class="has-vertical-middle has-text-centered is-content-width">{{ item.subQuoteNo }}</td>
        <td class="has-vertical-middle has-text-centered is-content-width">
          <a class="button is-primary is-small is-inverted"
            @click="addNewItem(item, index)">
            <span class="icon is-medium">
              <i class="mdi mdi-plus mdi-24px" />
            </span>
          </a>
        </td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
    </bulma-table>
  </div>
</template>

<script>
import QuoteService from '../QuoteService'
import { ItemCategoryTypes, QuotingMethodTypes } from '@/enums'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { AvailableMainLabourColumns } from '../columns'
import BulmaTable from '@/components/BulmaTable'
import _orderBy from 'lodash/orderBy'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { QuoteItemModel } from '@/classes/viewmodels'

export default {
  name: 'AvailableLaboursMain',
  directives: {},
  components: {
    BulmaTable
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    quoteId: {
      type: String,
      default: ''
    },
    quoteNo: {
      type: Number,
      default: 0
    },
    subQuoteNo: {
      type: Number,
      default: 0
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 8
    },
    quotingMethod: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innerValue: null,
      selectedRow: null,
      isTableLoading: false,
      filter: {
        quoteId: this.quoteId,
        query: '',
        butterflyCode: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      availableItems: null
    }
  },
  computed: {
    columns() {
      return AvailableMainLabourColumns
    },
    totalRows() {
      if (this.activeItems) {
        return this.activeItems.length
      } else {
        return 0
      }
    },
    activeItems() {
      if (this.availableItems) {
        const vm = this
        return _orderBy(
          this.availableItems.filter(function(item) {
            const regex = new RegExp(vm.filter.query, 'i')
            return !item.deleted && (item.itemDesc.match(regex) || item.itemNo.match(regex))
          }),
          ['subQuoteNo', 'sortNo'],
          ['asc']
        )
      } else {
        return []
      }
    },
    pagedActiveItems() {
      return this.activeItems.slice((this.filter.pageIndex - 1) * this.filter.pageSize, this.filter.pageIndex * this.filter.pageSize)
    }
  },
  watch: {
    // innerValue: {
    //   handler: function(newVal, oldVal) {
    //     this.$emit('input', newVal)
    //   },
    //   deep: true
    // }
  },
  created() {
    // this.innerValue = this.value
    this.getAvailableItems()
  },
  mounted() {},
  methods: {
    async getAvailableItems() {
      this.isTableLoading = true
      this.availableItems = await QuoteService.getQuoteItemsExcludeSubQuote(this.quoteNo, this.subQuoteNo, [
        ItemCategoryTypes.RR,
        ItemCategoryTypes.PAINT,
        ItemCategoryTypes.REP,
        ItemCategoryTypes.RWA
      ])
      this.isTableLoading = false
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    search() {
      this.filter.pageIndex = 1
    },
    reset() {
      this.filter.query = ''
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
    },
    addNewItem(item, index) {
      const existingItem = this.value.find(
        i => i.itemDesc === item.itemDesc && i.itemNo === item.itemNo && !i.deleted && i.itemType === item.itemType && i.deleteOtherItem
      )
      const rate = this.quotingMethod === QuotingMethodTypes.Dollar ? 1 : item.rate
      if (!existingItem) {
        let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.itemDesc, item.itemType)
        newItem.lineNumber = this.nextLineNumber
        newItem.hourValue = item.reportOnly ? item.hourValue : item.hourValue * -1
        newItem.rate = rate
        newItem.dollarValue = roundAwayFromZero(item.reportOnly ? item.hourValue * rate : item.hourValue * rate * -1)
        newItem.opgCode = this.vehiclePaintGroup
        newItem.sortNo = this.value.length ? Math.max(...this.value.map(i => i.sortNo)) + 1 : 1
        newItem.deleteOtherItem = true
        newItem.deletedItemId = item.quoteItemId
        this.$emit('item-added', [newItem])
      } else {
        this.$notification.openNotificationWithType('warning', 'Labour', 'Labour item is already in selected list. Item not added.')
      }
    }
  }
}
</script>
